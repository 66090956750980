(function () {
    'use strict';

    var PatientQualityOfLifePictoBlock = {
        bindings: {
            visits: '=',
        },
        controller: PatientQualityOfLifePictoBlockController,
        controllerAs: 'vm',
        templateUrl: 'app/activity/qol/patient-qol-picto-block.html'
    };

    angular
        .module('continuumplatformApp')
        .component('patientQualityOfLifePictoBlock', PatientQualityOfLifePictoBlock);

    PatientQualityOfLifePictoBlockController.$inject = ['$state'];

    function PatientQualityOfLifePictoBlockController($state) {

        const vm = this;
        vm.openVisitDetails = openVisitDetails;

        vm.$onInit = function () {
            console.log('vm.visits', vm.visits);
            initGraph();
            initGraphData();
        };

        function initGraph() {
            vm.chart = {
                labels: ["Psychologique", "Physique", "Intime", "Sociale", "Affective"],
                data: [],
                colors: ['#273FDC99', '#ded73f', '#de5b5b', '#81bd41'],
                options: {
                    scale: {
                        angleLines: {
                            display: false
                        },
                        beginAtZero: true,
                        ticks: {
                            suggestedMin: 0,
                            suggestedMax: 10
                        }
                    },
                    legend: {
                        display: true,
                    },
                    responsive: true,
                    maintainAspectRatio: true,
                },
                datasetOverride: []
            };
        }

        function initGraphData() {
            const filteredVisits = vm.visits.filter( (v) => v.steps.includes('PHARMA_ITW_QOL_PICTO')).slice(0,3);


            filteredVisits.forEach( (v) => {
                if (v.data && v.data.details && v.data.details.PHARMA_ITW_QOL_PICTO) {
                    const data = Object.assign({}, v.data.details.PHARMA_ITW_QOL_PICTO);

                    vm.chart.data.push([data.Q1, data.Q2, data.Q3, data.Q4, data.Q5]);

                    const dateInterview = (v.date ? moment(v.date) : moment()).format("DD/MM/YYYY");
                    vm.chart.datasetOverride.push({label: dateInterview});
                }
            });

        }

        function openVisitDetails(visitId) {
            $state.go('visit-detail', {id: visitId});
        }
    }

})();
