(function () {
    'use strict';

    const qualityOfLifeFactIcm = {
        bindings: {
            data: '=',
            patient: '=',
            visit: '<',
        },
        controller: QualityOfLifeFactIcmController,
        controllerAs: 'vm',
        templateUrl: 'app/activity/visit/steps/fact-icm/qol-FACT-ICM.html'
    };

    angular
        .module('continuumplatformApp')
        .component('qualityOfLifeFactIcm', qualityOfLifeFactIcm);

    QualityOfLifeFactIcmController.$inject = ['$rootScope', '$scope', '$state', '$timeout', 'FactIcmService'];

    function QualityOfLifeFactIcmController($rootScope, $scope, $state, $timeout, FactIcmService) {

        const vm = this;

        vm.step = 'QOL_FACT_ICM';
        vm.wizardStep = 0;
        vm.question = null;
        vm.questionsProgress = 0;
        vm.localStorageData = {};

        vm.onClickResponse = onClickResponse;
        vm.onClickFinishAnotherTime = onClickFinishAnotherTime;

        vm.$onInit = function () {
            initQuestions();
            initData() ;

            subscribeBoxWith();
        };

        vm.$onDestroy = function () {
            if (vm.unwatchBoxWidth) {
                vm.unwatchBoxWidth();
            }
        };

        function initData() {
            vm.localStorageData = getLocalStorageData();
            const localSaveFactIcm = vm.localStorageData && vm.localStorageData[vm.step] ? vm.localStorageData[vm.step] : {};
            vm.data = vm.data ? Object.assign(vm.data, localSaveFactIcm.data) : Object.assign({}, localSaveFactIcm.data);
            vm.wizardStep = localSaveFactIcm.questionIndex ? localSaveFactIcm.questionIndex : 0;
            vm.question = vm.questions[vm.wizardStep];

            calculateProgress();

            const flatQuestions = vm.questions.flat();
            for (let i = 0; i < flatQuestions.length; i++) {
                flatQuestions[i].score = vm.data[flatQuestions[i].id];
            }
        }

        function getLocalStorageData() {
            const dataString = localStorage.getItem(`visit-data-${vm.visit.id}`);
            return dataString ? JSON.parse(dataString) : {};
        }

        /**
         *
         */
        function subscribeBoxWith() {
            vm.unwatchBoxWidth = $scope.$watch(
                () => document.querySelector('.box').offsetWidth,
                (width) => vm.boxWidth = width,
                true
            );
        }

        /**
         * Execute when the user click on response to a question
         * @param question the question
         * @param response the response to the question
         */
        function onClickResponse(question, response) {
            if (!vm.data) {
                vm.data = {};
            }

            vm.question.score = response;
            // store the response on step data
            vm.data[vm.question.id] = response;

            vm.next();
        }

        function onClickFinishAnotherTime() {
            $state.go('home');
        }

        vm.finish = function () {
            vm.data.scoring = FactIcmService.calculateScore(vm.questions);

            vm.wizardStep = 0;
            saveDataToLocalStorage();

            $rootScope.$emit('continuumplatformApp:visit-wizard:finishStep', {
                step: vm.step
            });
        };

        vm.previous = function() {
            vm.wizardStep--;
            vm.question = vm.questions[vm.wizardStep];

            saveDataToLocalStorage();
            calculateProgress();
        };

        /**
         * Show the next question.
         */
        vm.next = function(dontWantRespond) {
            if (vm.nextAlreadyClicked) {
                return;
            }
            vm.nextAlreadyClicked = true;

            if (dontWantRespond) {
                vm.question.score = null;
                vm.data[vm.question.id] = null;
            }

            $timeout(() => {
                vm.wizardStep++;
                saveDataToLocalStorage();

                vm.question = vm.questions[vm.wizardStep];
                calculateProgress();
                vm.nextAlreadyClicked = false;
            }, 300);



        };

        function saveDataToLocalStorage() {
            vm.localStorageData[vm.step] = { data : vm.data, questionIndex: vm.wizardStep};
            localStorage.setItem(`visit-data-${vm.visit.id}`, JSON.stringify(vm.localStorageData));
        }

        function calculateProgress() {
            vm.questionsProgress = 0;
            if (vm.questions[vm.wizardStep]) {
                vm.questionsProgress = Math.round((vm.wizardStep - 1) / vm.questions.length * 100);
            } else {
                vm.questionsProgress = 100;
            }
        }

        function initQuestions() {
            vm.questions = [{}, ...FactIcmService.questions];
        }

    }

})();
