(function () {
    'use strict';

    const patientQolFactIcmBlock = {
        bindings: {
            visits: '=',
        },
        controller: PatientQolFactIcmBlockController,
        controllerAs: 'vm',
        templateUrl: 'app/activity/qol/patient-qol-fact-icm-block.html'
    };

    angular
        .module('continuumplatformApp')
        .component('patientQolFactIcmBlock', patientQolFactIcmBlock);

    PatientQolFactIcmBlockController.$inject = ['$state'];

    function PatientQolFactIcmBlockController($state) {

        const vm = this;

        vm.openVisitDetails = openVisitDetails;

        vm.$onInit = () => {
            initGraph();
        };

        function initGraph() {
            vm.chart = {
                series: ['Scores'],
                labels: [],
                data: [[]],
                options: {
                    title: {
                        display: true,
                        text: 'Score total FACT-ICM',
                        fontSize: 16
                    },
                    scales: {
                        yAxes: [
                            {
                                display: true,
                                scaleLabel: {display: true},
                                ticks: {
                                    stepSize: 1,
                                    // beginAtZero: true
                                }
                            }
                        ]
                    },
                },
                datasetOverride: [{}],
            };

            vm.visits.sort((v1, v2) => {
                return v1.date.localeCompare(v2.date);
            }).forEach((v) => {
                vm.chart.labels.push(moment(v.date).format('DD/MM/YY'));
                vm.chart.data[0].push(v.data.details['QOL_FACT_ICM'].scoring['TOTAL_FACT_ICM_SCORE']);
            });

        }


        function openVisitDetails(visitId) {
            $state.go('visit-detail', {id: visitId});
        }

    }

})();
